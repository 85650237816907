export const setField = field => {
    const name = field.target.name
    const value = field.target.value
    return {
        type: 'SET_FIELD',
        payload: {
            name,
            value
        }
    }
};

export const setHomeCityState = data => {
    return {
        type: 'SET_HOME_CITY_STATE',
        payload: {
            home_city: data.places[0]['place name'],
            home_state: data.places[0]['state abbreviation'],
        },
    };
};

export const clearHomeCityState = () => {
    return {
        type: 'CLEAR_HOME_CITY_STATE',
        payload: {
            home_city: "",
            home_state: ""
        }
    }
}

export const setAffParams = ({ affid, aff_id, sub_id, sub_id1, sub_id2, sub_id3, offer_id }) => {
    return {
        type: 'SET_AFF_PARAMS',
        payload: {
            sub_id1: sub_id || sub_id1 || "s1",
            sub_id2: sub_id2 || "s2",
            sub_id3: sub_id3 || "s3",
            client_name: aff_id || affid || `${process.env.GATSBY_AFFID}`,
            affid: aff_id || affid || `${process.env.GATSBY_AFFID}`,
            offer_id: offer_id || '1',
            phone_number: setPhone(aff_id || affid || `${process.env.GATSBY_AFFID}`)
        }
    }
}

export const setIpAddress = (ip_address) => {
    return {
        type: 'SET_IP_ADDRESS',
        payload: {
            ip_address
        }
    }
}

export const setZipCode = (zip) => {
    return {
        type: 'SET_ZIP_CODE',
        payload: {
            zip
        }
    }
}

export const setUniversalLeadId = (universal_leadid) => {
    return {
        type: 'SET_UNIVERSAL_LEAD_ID',
        payload: {
            universal_leadid
        }
    }
}

export const setUserAgent = (user_agent) => {
    return {
        type: 'SET_USER_AGENT',
        payload: {
            user_agent
        }
    }
}

export const nextPage = () => {
    return {
        type: 'NEXT_PAGE'
    }
}

export const prevPage = () => {
    return {
        type: 'PREV_PAGE'
    }
}

export const setSliderForm = () => {
    return {
        type: 'SLIDER_FORM'
    }
}

export const setStateTextValue = (state) => {
    return {
        type: 'SET_STATE_TEXT_VALUE',
        state
    }
}

// Set phone number depending of affid
const setPhone = (client_name) => {
    console.log('client name---->', client_name)
    switch (client_name) {
        case '98lk325':
            return '800-295-0433';
        case '215medib':
            return '877-203-9668';
        case 'aetcalls':
            return '888-200-8931';
        default:
            return '800-549-9044'
    }
}
