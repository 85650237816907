import React from 'react';
import { navigate } from 'gatsby';
import Logo from '../images/logo.png'
import PhoneNumber from './phoneNumber';

const Header = () => {
    const handleClickLogo = () => {
        navigate('/');
    };
    return (
        <header >
            <div className="header">
                <div className="logo" onClick={handleClickLogo}>
                    <img src={Logo} alt="logo" />
                    <p>MediCareMaster<span>.org</span></p>
                </div>
                <div className="header-information">
                    <p>Call And Speak To A Live Agent Free</p>
                    <PhoneNumber />
                    <span>Mon - Fri, 8am - 8pm ET</span>
                </div>
            </div>
        </header>
    );
};

export default Header;
