import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux';

const PhoneNumber = ({noIcon}) => {
    const client_name = useSelector((state) => state.meta.client_name);

    const genderatePhoneNumber = () => {
        switch (client_name) {
            case '98lk325':
                return '800-295-0433';
            case '215medib':
                return '877-203-9668';
            case 'aetcalls':
                return '888-200-8931';
            default:
                return '800-549-9044'
        }
    }

    return (
        <a href={`tel:${genderatePhoneNumber()}`} className="phone-link">
            {!noIcon && <FontAwesomeIcon icon={faPhoneAlt} />} {genderatePhoneNumber()}
        </a>

    )
}

export default PhoneNumber;
