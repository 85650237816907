import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div>
                    <Link to='/privacy'>Privacy Policy</Link>
                    <Link to='/terms'>Terms of Use</Link>
                    <Link to='/contact'>Contact Us</Link>
                </div>
                <p>Copyright © {new Date().getFullYear()} MediCareMaster.org.
                    <br/>All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
