/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import {
  setIpAddress,
  setAffParams,
  setUniversalLeadId,
  setUserAgent,
} from '../state/action';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlParams } from '../helper';

import '../scss/main.scss';
import Header from './header';
import Footer from './footer';

const Layout = ({ children, location }) => {
  const dispatch = useDispatch();
  const { ip_address, user_agent } = useSelector(
    (state) => state.meta,
  );
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    dispatch(setUniversalLeadId(document.getElementById('leadid_token').value));
    const params = getUrlParams(location.search);
    dispatch(setAffParams(params));
  }, []);

  useEffect(() => {
    if (!user_agent) dispatch(setUserAgent(navigator.userAgent));
  }, []);

  useEffect(() => {
    if (!ip_address)
      fetch('https://ipapi.co/json/')
        .then((response) => {
          if (response.status !== 200) {
            console.log(
              `Looks like there was a problem. Status Code: ${response.status}`,
            );
            dispatch(setIpAddress('127.0.0.1'));
            return;
          }
          // Examine the text in the response
          response.json().then((data) => {
            dispatch(setIpAddress(data.ip));
          });
        })
        .catch((err) => {
          dispatch(setIpAddress('127.0.0.1'));
          console.log('Error', err);
        });
  }, [dispatch, location.search]);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
